

















































































































































import { Component, Vue } from "vue-property-decorator";
import { usuario } from "@/shared/dtos/usuario";
import { MessageService } from "@/shared/services/message-service";
import usuarioModule from "@/store/modules/usuario-module";
import { singleOrDoubleRowClick } from "@/utils/dblclickrow";
import ayuntamientoModule from "@/store/modules/ayuntamiento-module";
import { ssmPermissionService } from "@/shared/services/PermissionService";
import { Permission } from "@/shared/permission";
import { medicalUserService } from "@/shared/services/user-service";
@Component({
  components: {},
  $_veeValidate: { validator: "new" },
})
export default class UsuariosLista extends Vue {
  public search: string = "";
  public show_dialog: boolean = false;
  public obj_usuario: usuario = new usuario();
  public es_edicion: boolean = false;
  public radioGroup: number = 1;

  public created() {
    usuarioModule.getusuarios();
    ayuntamientoModule.getayuntamientos();
  }

  public get tipos_permisos() {
    return medicalUserService.tipos_permisos;
  }
  public get datasource() {
    return usuarioModule.usuarios;
  }

  public get datasource_ayuntamiento() {
    return ayuntamientoModule.ayuntamientos;
  }

  public get headers() {
    let header: any = [
      {
        text: "Nombre",
        align: "start",
        value: "nombre",
      },
      { text: "Email", value: "email" },
      { text: "Teléfono", value: "telefono" },
    ];
    if (this.HasPermision()) {
      header.push({ text: "Admin", value: "is_admin" });
      header.push({ text: "Ayuntamiento", value: "ayuntamiento.nombre" });
    }
    header.push({ text: "", value: "acciones", align: "end" });

    return header;
  }

  public HasPermision() {
    return ssmPermissionService.hasPermission(Permission.read_admin);
  }

  /**
   * editar
   */
  public editar_rw(obj: usuario) {
    this.obj_usuario = new usuario(obj);
    this.put_radio_btn();
    this.es_edicion = true;
    this.show_dialog = true;
  }

  /**
   * add_new
   */
  public add_new() {
    this.$validator.reset();
    this.show_dialog = true;
  }
  /**
   *eliminar
   */
  public eliminar(obj: usuario) {
    MessageService.confirm(
      "¿Seguro que quiere eliminar " + obj.nombre + "?",
      "Eliminar",
      (x: boolean) => {
        if (x) {
          usuarioModule.eliminarusuario(obj);
        }
      }
    );
  }
  public change_admin() {
    switch (this.radioGroup) {
      case 1:
        this.obj_usuario.is_admin = false;
        this.obj_usuario.solo_datos = false;
        break;
      case 2:
        this.obj_usuario.is_admin = true;
        this.obj_usuario.solo_datos = false;
        this.obj_usuario.id_ayuntamiento = 0;
        break;
      case 3:
        this.obj_usuario.is_admin = false;
        this.obj_usuario.solo_datos = true;
        this.obj_usuario.id_ayuntamiento = 0;
        break;
    }
  }

  public put_radio_btn() {
    this.radioGroup = medicalUserService.get_id_user_permission(
      this.obj_usuario
    );
  }
  /**
   * cancelar
   */
  public cancelar() {
    this.show_dialog = false;
    this.obj_usuario = new usuario();
    this.es_edicion = false;
  }

  /**
   * aceptar
   */
  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.es_edicion) {
          usuarioModule
            .modificarusuario(this.obj_usuario)
            .then(() => this.cancelar());
        } else {
          usuarioModule
            .guardarusuario(this.obj_usuario)
            .then(() => this.cancelar());
        }
      }
    });
  }
  public dblclick(item: any, singleClickFunc: any, doubleClickFunc: any) {
    singleOrDoubleRowClick(item, singleClickFunc, doubleClickFunc);
  }
}
